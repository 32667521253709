<template>
  <div class="notice-board-page">
    <v-row no-gutters class="my-2" justify="center">
      <v-col md="6">
        <intract-smart-list
          ref="notice-posts-smart-list"
          :endpoint="noticePostsEndpoint"
          paginated
          disable-search
          :filter-fields="showFilterOptions ? filterFields : null "
        >
            <template v-slot:custom-filter--dateRange>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    clearable
                    @click:clear="date = null"
                    :value="formattedDateRangeString"
                    label="Filter by Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" range>
                  <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                      <span
                        v-if="date && date.length == 1"
                        class="text-caption red--text"
                        >Please select End Date as well</span
                      >
                    </v-col>
                    <v-col class="text-right">
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        :disabled="date && date.length == 1"
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-dialog>
            </template>
          <template v-slot:list-item="{ item: post }">
            <v-card
              class="mx-2 my-3"
              @click="
                  $router.push({
                    name: 'ViewBoardPost', 
                    params: { postId: post.id },
                  })
              "
            >
            <v-list-item
            >
              <v-list-item-avatar>
                <v-img :src="currentInstitution.logo"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="subtitle-2">
                {{ currentInstitution.name }}
              </v-list-item-content>
              <p class="my-0 caption grey--text">
                {{ moment(post.created).format("hh:mmA, Do MMM") }}
              </p>

            </v-list-item>
            <v-card-text class="py-1 pb-2">
              <div class="black--text subtitle-1 content-description">{{ post.description | truncate(150, '...') }}</div>
              <v-img
                v-if="post.board_images.length"
                :src="post.board_images[0].image"
              ></v-img>
            </v-card-text>
            <v-card-actions
              class="pt-0 ml-1"
              v-if="post.board_images.length || post.board_documents.length || post.is_draft"
            >
              <v-chip small
                color="orange lighten-2 mr-1"
                v-if="post.is_draft && post.scheduled"
              >Scheduled</v-chip>

              <v-chip
                small
                color="light-blue lighten-4 mr-1"
                v-if="post.board_images.length && post.board_images.length > 1"
                >+{{ post.board_images.length - 1 }} Images</v-chip
              >
              <v-chip
                small
                color="teal lighten-4"
                v-if="post.board_documents.length"
                >{{ post.board_documents.length }} Document(s)</v-chip
              >

            </v-card-actions>
            </v-card>
            <!-- <v-divider class="mx-4"></v-divider>         -->
          </template>
        </intract-smart-list>

        <!-- </div> -->
      </v-col>
    </v-row>


    <v-fab-transition v-if="currentUser.is_admin && !isLoading">
      <v-btn
        color="primary"
        @click="noticeCreateSheet.visible = true"
        dark
        bottom
        right
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- <create-board-post-sheet
      :visible="showCreateBoardPostSheet"
      reset-after-submission
      @close="showCreateBoardPostSheet = false"
      @success="getNoticePosts"
      :post-body="noticePostBody"
    /> -->

    <intract-create-edit-sheet
      persistent
      v-if="currentUser.is_admin"
      title="Send Notice"
      description="This will be notified to all the students and the staff"
      :visible="noticeCreateSheet.visible"
      @close="noticeCreateSheet.visible = false"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.boardPosts"
      :edit-id="noticeCreateSheet.editId"
      :data-object="noticeCreateSheet.obj"
      :create-success-message="noticeCreateSheet.createSuccessMessage"
      @objectCreated="noticeCreated"
      @updateObject="(obj) => (noticeCreateSheet.obj = obj)"
    >
      <!-- schedule for later button slot -->
      <template v-slot:after-submit-button v-if="!noticeCreateSheet.obj.scheduled">
        <v-dialog
            v-model="scheduleDialog.visible"
            width="500"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                outlined
                block
                dark
                v-bind="attrs"
                v-on="on"
              >
                Schedule for Later
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Schedule for later
               <v-btn icon absolute top right @click="scheduleDialog.visible=false; scheduleDialog.datetime=null">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
              </v-card-title>

              <v-card-text>
                <v-text-field class="mt-4 mb-0" hide-details="auto" dense outlined type="datetime-local" label="Schedule Date & Time" v-model="scheduleDialog.datetime">
                </v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  block
                  :disabled="!scheduleDialog.datetime"
                  @click="noticeCreateSheet.obj.scheduled = scheduleDialog.datetime; scheduleDialog.visible = false; scheduleDialog.datetime = null"
                >
                  Schedule 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 
      </template>

      <!-- scheduled for information -->
      <template v-slot:custom-field--scheduled="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Scheduled for <strong class="orange--text">{{ moment(obj.scheduled).format('LLL') }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Will be published at the specified time.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.scheduled = null">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </intract-create-edit-sheet>
  </div>
</template>
<script>
import IntractSmartList from "@components/generics/IntractSmartList";
import { mapActions, mapGetters } from "vuex";
import Mixins from '@utils/mixins';
import moment from "moment";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import EventBus from "@utils/event_bus";

export default {
  name: "OldNoticeBoard",
 
  data() {
    return {
      date: null, 
      modal: false,
      moment: moment,
      showFilterOptions: false,
      noticePostBody: {
        is_alert: false,
        description: "",
        institution: null,
        recipients: [],
      },
      scheduleDialog: {
        visible: false,
        datetime: null,
      },
      noticeCreateSheet: {
        visible: false,
        createSuccessMessage: "Notice sent successfully!",
        editId: null,
        obj: {
          is_alert: false,
          description: "",
          institution: null,
          recipients: [],
          allow_replies: false,
          for_staff: false,
          scheduled: null,
        },
      },
    };
  },
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractSmartList,
    IntractCreateEditSheet,
  },
  computed: {
    filterFields() {
      return {
        dateRange: {
          model: null,
          label: "Filter by Date",
          custom: true,
        },
      };
    },

    appBarOptions() {
      return {
        iconButton: { 
          icon: "mdi-filter-variant", 
          action: () => {
            // toggle filter options
            this.showFilterOptions = !this.showFilterOptions
          }, 
        },
      }
    },

    formattedDateRangeString() {
      if (!this.date) return null;
      if (this.date.length == 0) return "";
      else if (this.date.length == 1)
        return moment(this.date[0]).format("ll") + " - ";
      else
        return (
          moment(this.date[0]).format("ll") +
          " - " +
          moment(this.date[1]).format("ll")
        );
    },
    ...mapGetters([
      "isLoading",
      "currentUser",
      "accessToken",
      "currentInstitution",
    ]),


    noticePostsEndpoint() {
      var endpoint_url = this.Helper.addUrlParams(this.endpoints.boardPosts, "is_alert=false") 
      
      if(this.currentUser.is_student) //hide drafts and faculty posts from students
        endpoint_url = this.Helper.addUrlParams(endpoint_url, ['is_draft=false', 'for_staff=false']);

      // Filter by date
      var dateFilter = "";
      if (this.showFilterOptions && this.date && this.date.length == 2) {
        var startDate,
          endDate = null;
        if (moment(this.date[0]).isSameOrBefore(moment(this.date[1]))) {
          startDate = this.date[0];
          endDate = this.date[1];
        } else {
          startDate = this.date[1];
          endDate = this.date[0];
        }
        dateFilter = `created__gte=${moment(startDate).format(
          this.Helper.dateURLFormat
        )}&created__lte=${moment(endDate).format(this.Helper.dateURLFormat)}`;
 
          endpoint_url = this.Helper.addUrlParams(endpoint_url, dateFilter);
      }

      return endpoint_url;
    },
    formFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        allow_replies: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Allow Replies",
          helper: "Will allow students to add replies to this notice. Replies will also be visible to other students.",
          required: false,
          md: 12,
        },
        for_staff: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "For staff only",
          helper: "This will keep the notice visible to staff and hidden from students.",
          required: false,
          md: 12,
        },
        scheduled: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: this.noticeCreateSheet.obj.scheduled ? false: true,
        },
        board_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        board_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    ...mapActions(["showSnackbar", "setLoading"]),

    async noticeCreated(data) {
      this.onRefresh();
      this.$router.push({ name: "ViewBoardPost", params: { postId: data.id } });
    },

    async initListeners() {
      EventBus.$on(
        "view_board_post__post_deleted",
        // (id) => (this.posts = this.posts.filter((p) => p.id != id))
        (id) => (this.$refs["notice-posts-smart-list"].removeItem(id)),   
      );

      EventBus.$on("view_board_post__post_updated", (post) => {
        if (this.$refs["notice-posts-smart-list"])
          this.$refs["notice-posts-smart-list"].replaceItem(post.id, post);
      });
    },
    async onRefresh() {
      if (this.$refs["notice-posts-smart-list"])
          this.$refs["notice-posts-smart-list"].getItems();
      },    
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      console.log("destroying");
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "NoticeBoard");
    }
    next();
  },
  created() {
    this.onRefresh();
    this.noticeCreateSheet.obj.recipients.push(this.currentUser.id);
    this.noticeCreateSheet.obj.user = this.currentUser.id;
    this.noticeCreateSheet.obj.institution = this.currentInstitution.id;
    this.initListeners();
  },
};
</script>
<style scoped>
.fab-transition-enter {
  transition-delay: 2s;
}
</style>